import { mapState } from 'pinia';
import { useRoute } from 'nuxt/app';
import { useProductsStore } from '~~/common/stores/products';

const RETURN_PRODUCT_LIST_HISTORY_SCROLL_ATTEMPT_MAX = 1000;

export default {
  computed: {
    ...mapState(useProductsStore, {
      isReturningFromProductPage (state) {
        const route = useRoute();
        return state.productListHistoryInformation?.location?.includes(route.fullPath);
      },
      isReturningEnabled (state) {
        return state.productListHistoryInformation?.returnEnabled;
      },
    }),

  },
  methods: {
    scrollToProductId (productId) {
      if (this.returnProductListHistoryScrollAttempt >= RETURN_PRODUCT_LIST_HISTORY_SCROLL_ATTEMPT_MAX || this.returnProductListHistoryScrollSuccess) {
        window.scrollTo(0, 0);
        return;
      }
      const productCard = document.querySelector('.prodPageReturn-' + productId);
      if (productCard) {
        requestAnimationFrame(() => {
          productCard.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        });
        this.returnProductListHistoryScrollSuccess = true;
      } else {
        this.returnProductListHistoryScrollAttempt++;
        setTimeout(() => this.scrollToProductId(productId), 100);
      }
    },
  },
  data () {
    return {
      returnProductListHistoryScrollAttempt: 0,
      returnProductListHistoryScrollSuccess: false,
    };
  },
};
